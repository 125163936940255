@import url('https://fonts.googleapis.com/css2?family=Quicksand');

body {
  font-family: 'Quicksand';
  min-width: 0 !important;
  font-size: 14px;
}

.videoContainerScroll {
  overflow: scroll;
  -webkit-overflow-scrolling: touch;
}

.videoContainerScroll::-webkit-scrollbar {
  width: 3px;
}


#zmmtg-root {
  display: none;
}
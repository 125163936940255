.pollsView {
  #date-picker-inline-label,
  #time-picker-label {
    color: white !important;

    svg {
      color: #707477 !important;
    }
  }

  #date-picker-inline,
  #time-picker-label {
    color: white !important;

    svg {
      color: #707477 !important;
    }
  }

  .MuiInput-underline:before {
    border-bottom-color: 1px solid #707477 !important;
  }

  .MuiInputBase-input {
    color: white !important;
    font-size: 14px !important;
  }
  .MuiIconButton-root {
    .MuiIconButton-label {
      .MuiSvgIcon-root {
        color: #707477 !important;
      }
    }
  }
}

.react-add-to-calendar {
  font-size: 12px;
  color: #034522;
  font-weight: 100;
  padding-right: 5px;

  .react-add-to-calendar__dropdown {
    margin-top: -10%;
    font-size: 11px;
    color: #034522;
  }
  ul {
    list-style-type: none;
    padding-left: 26%;
  }
  a {
    color: #034522;
    text-decoration: none;
  }

  a:hover {
    color: #034522;
    text-decoration: none;
    cursor: pointer;
  }
}

@import url('https://fonts.googleapis.com/css?family=Assistant');

body {
  margin: 0;
  font-family: 'Assistant', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overscroll-behavior-y: contain;
  /* background-color: #034522; */
}

code {
  font-family: 'Assistant', sans-serif;
}

#root {
  /* background-color: #034522; */
}

.MuiBox-root {
}

.MuiTypography-subtitle1 {
    display: none;
}

.MuiButton-containedPrimary {
    background: #4d4b62 !important;
    border-radius: 21px !important;
}

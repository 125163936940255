.main-page-container {
  .react-swipeable-view-container > div[aria-hidden='false'] {
    height: 100%;
  }

  .react-swipeable-view-container > div[aria-hidden='true'] {
    height: 100vh;
  }
}

.notifications-container {
  .react-swipeable-view-container > div[aria-hidden='false'] {
    height: 100%;
  }

  .react-swipeable-view-container > div[aria-hidden='true'] {
    height: 0;
  }
}

.whiteBgContainer {
  .react-swipeable-view-container > div[aria-hidden='false'] {
    height: 100%;
  }

  .react-swipeable-view-container > div[aria-hidden='true'] {
    height: 0;
  }
}

.add-to-home-banner {
  font-family: inherit !important;
  background: #ececec !important;
  font-weight: bold !important;
  height: 70px !important;
  .add-to-home-text {
    font-size: 20px !important;
  }
}
